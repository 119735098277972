import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'


Vue.use(VueRouter)

const routes = [{
        path: "/",
        meta: {
            requiresAuth: true
        },


        component: () => import('@templates/default/Index'),
        children: [{
                path: '',
                name: 'Home',
                component: () => import('@pages/Home/Home')
            },
            {
                path: 'perfil/user/:id',
                props: true,
                name: 'PerfilUser',
                meta: {
                    title: "Meu Perfil"
                },
                component: () => import('@pages/PerfilUser/PerfilUser')
            },
            {
                path: 'perfil/user/senha/:id',
                props: true,
                name: 'UserSenhaEdit',
                meta: {
                    title: "Alterar Senha"
                },
                component: () => import('@pages/PerfilUser/UserSenhaEdit')
            },
            {
                path: 'cliente',
                name: 'Cliente',
                meta: {
                    title: "Clientes",
                    permissions: ['cliente']
                },
                component: () => import('@pages/Cliente/Cliente')
            },
            {
                path: 'cliente/create',
                props: true,
                name: 'ClienteCreate',
                meta: {
                    title: "Cadastro de Cliente",
                    permissions: ['cliente']
                },
                component: () => import('@pages/Cliente/ClienteCreate')
            },
            {
                path: 'cliente/edit/:id',
                props: true,
                name: 'ClienteEdit',
                meta: {
                    title: "Edição de Cliente",
                    permissions: ['cliente']
                },
                component: () => import('@pages/Cliente/ClienteEdit')
            },
            {
                path: 'cliente/show/:id',
                props: true,
                name: 'ClienteShow',
                meta: {
                    title: "Visualizar Cliente",
                    permissions: ['cliente']
                },
                component: () => import('@pages/Cliente/ClienteShow')
            },
            {
                path: 'colaborador',
                name: 'Funcionario',
                meta: {
                    title: "Colaboradores",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/Funcionario')
            },
            {
                path: 'colaborador/create',
                name: 'FuncionarioCreate',
                meta: {
                    title: "Cadastro de Colaborador",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/FuncionarioCreate')
            },
            {
                path: 'colaborador/edit/:id',
                props: true,
                name: 'FuncionarioEdit',
                meta: {
                    title: "Edição de Colaborador",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/FuncionarioEdit')
            },
            {
                path: 'colaborador/show/:id',
                props: true,
                name: 'FuncionarioShow',
                meta: {
                    title: "Visualizar Colaborador",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/FuncionarioShow')
            },
            {
                path: 'colaborador/show/acesso/:id',
                props: true,
                name: 'FuncionarioAcessoEdit',
                meta: {
                    title: "Editar Acesso",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/AcessoEdit')
            },
            {
                path: 'colaborador/show/dados/:id',
                props: true,
                name: 'FuncionarioDadosEdit',
                meta: {
                    title: "Editar Dados Bancários",
                    permissions: ['colaborador']
                },
                component: () => import('@pages/Funcionario/DadosBancariosEdit')
            },
            {
                path: 'empresa',
                props: true,
                name: 'Empresa',
                meta: {
                    title: "Empresas",
                    permissions: ['empresa']
                },
                component: () => import('@pages/Empresa/Empresa')
            },
            {
                path: 'empresa/create',
                props: true,
                name: 'EmpresaCreate',
                meta: {
                    title: "Cadastro de Empresa",
                    permissions: ['empresa']
                },
                component: () => import('@pages/Empresa/EmpresaCreate')
            },
            {
                path: 'empresa/edit/:id',
                props: true,
                name: 'EmpresaEdit',
                meta: {
                    title: "Edição de Empresa",
                    permissions: ['empresa']
                },
                component: () => import('@pages/Empresa/EmpresaEdit')
            },
            {
                path: 'empresa/show/:id',
                props: true,
                name: 'EmpresaShow',
                meta: {
                    title: "Visualizar Empresa",
                    permissions: ['empresa']
                },
                component: () => import('@pages/Empresa/EmpresaShow')
            },

            {
                path: 'produto',
                name: 'Produto',
                meta: {
                    title: "Produtos",
                    permissions: ['produto']
                },
                component: () => import('@pages/Produto/Produto')
            },
            {
                path: 'produto/maisvendidos',
                name: 'MaisVendidos',
                meta: {
                    title: "Produtos",
                    permissions: ['mais_vendido']
                },
                component: () => import('@pages/Produto/MaisVendidos')
            },
            {
                path: 'produto/create',
                name: 'ProdutoCreate',
                meta: {
                    title: "Cadastro de Produto",
                    permissions: ['produto']
                },
                component: () => import('@pages/Produto/ProdutoCreate')
            },
            {
                path: 'produto/edit/:id',
                props: true,
                name: 'ProdutoEdit',
                meta: {
                    title: "Edição de Produto",
                    permissions: ['produto']
                },
                component: () => import('@pages/Produto/ProdutoEdit')
            },
            {
                path: 'produto/estoque/:id',
                props: true,
                name: 'ProdutoEstoque',
                meta: {
                    title: "Estoque",
                    permissions: ['produto']
                },
                component: () => import('@pages/Produto/ProdutoEstoque')
            },

            {
                path: 'fornecedor',
                name: 'Fornecedor',
                meta: {
                    title: "Fornecedores",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/Fornecedor/Fornecedor')
            },
            {
                path: 'fornecedor/create',
                name: 'FornecedorCreate',
                meta: {
                    title: "Cadastro de Fornecedor",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/Fornecedor/FornecedorCreate')
            },
            {
                path: 'fornecedor/edit/:id',
                props: true,
                name: 'FornecedorEdit',
                meta: {
                    title: "Edição de Fornecedor",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/Fornecedor/FornecedorEdit')
            },
            {
                path: 'fornecedor/show/:id',
                props: true,
                name: 'FornecedorShow',
                meta: {
                    title: "Visualizar Fornecedor",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/Fornecedor/FornecedorShow')
            },
            {
                path: 'fornecedor/show/dados/:id',
                props: true,
                name: 'FornecedorDadosEdit',
                meta: {
                    title: "Editar Dados Bancários",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/Fornecedor/DadosBancariosEdit')
            },

            {
                path: 'terceirizado',
                name: 'Terceirizado',
                meta: {
                    title: "Fornecedores",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Terceirizado/Terceirizado')
            },
            {
                path: 'terceirizado/create',
                name: 'TerceirizadoCreate',
                meta: {
                    title: "Cadastro de Terceirizado",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Terceirizado/TerceirizadoCreate')
            },
            {
                path: 'terceirizado/edit/:id',
                props: true,
                name: 'TerceirizadoEdit',
                meta: {
                    title: "Edição de Terceirizado",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Terceirizado/TerceirizadoEdit')
            },
            {
                path: 'terceirizado/show/:id',
                props: true,
                name: 'TerceirizadoShow',
                meta: {
                    title: "Visualizar Terceirizado",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Terceirizado/TerceirizadoShow')
            },
            {
                path: 'terceirizado/show/dados/:id',
                props: true,
                name: 'TerceirizadoDadosEdit',
                meta: {
                    title: "Editar Dados Bancários",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Terceirizado/DadosBancariosEdit')
            },

            {
                path: 'arquiteto',
                name: 'Arquiteto',
                meta: {
                    title: "Fornecedores",
                    permissions: ['terceirizado']
                },
                component: () => import('@pages/Arquiteto/Arquiteto')
            },
            {
                path: 'arquiteto/create',
                name: 'ArquitetoCreate',
                meta: {
                    title: "Cadastro de Arquiteto",
                    permissions: ['arquiteto']
                },
                component: () => import('@pages/Arquiteto/ArquitetoCreate')
            },
            {
                path: 'arquiteto/edit/:id',
                props: true,
                name: 'ArquitetoEdit',
                meta: {
                    title: "Edição de Arquiteto",
                    permissions: ['arquiteto']
                },
                component: () => import('@pages/Arquiteto/ArquitetoEdit')
            },
            {
                path: 'arquiteto/show/:id',
                props: true,
                name: 'ArquitetoShow',
                meta: {
                    title: "Visualizar Arquiteto",
                    permissions: ['arquiteto']
                },
                component: () => import('@pages/Arquiteto/ArquitetoShow')
            },
            {
                path: 'arquiteto/show/dados/:id',
                props: true,
                name: 'ArquitetoDadosEdit',
                meta: {
                    title: "Editar Dados Bancários",
                    permissions: ['arquiteto']
                },
                component: () => import('@pages/Arquiteto/DadosBancariosEdit')
            },
            {
                path: 'categoria',
                name: 'Categoria',
                meta: {
                    title: "Categorias",
                    permissions: ['categoria']
                },
                component: () => import('@pages/Categoria/Categoria')
            },
            {
                path: 'categoria/create',
                name: 'CategoriaCreate',
                meta: {
                    title: "Cadastro de Categoria",
                    permissions: ['categoria']
                },
                component: () => import('@pages/Categoria/CategoriaCreate')
            },
            {
                path: 'categoria/edit/:id',
                props: true,
                name: 'CategoriaEdit',
                meta: {
                    title: "Edição de Categoria",
                    permissions: ['categoria']
                },
                component: () => import('@pages/Categoria/CategoriaEdit')
            },
            {
                path: 'garantia',
                name: 'Garantia',
                meta: {
                    title: "Garantias",
                    permissions: ['garantia']
                },
                component: () => import('@pages/Garantia/Garantia')
            },
            {
                path: 'garantia/create',
                name: 'GarantiaCreate',
                meta: {
                    title: "Cadastro de Garantia",
                    permissions: ['garantia']
                },
                component: () => import('@pages/Garantia/GarantiaCreate')
            },
            {
                path: 'garantia/edit/:id',
                props: true,
                name: 'GarantiaEdit',
                meta: {
                    title: "Edição de Garantia",
                    permissions: ['garantia']
                },
                component: () => import('@pages/Garantia/GarantiaEdit')
            },

            {
                path: 'condicaofaturamento',
                name: 'CondicaoFaturamento',
                meta: {
                    title: "Condições de Faturamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/CondicaoFaturamento/CondicaoFaturamento')
            },
            {
                path: 'condicaofaturamento/create',
                name: 'CondicaoFaturamentoCreate',
                meta: {
                    title: "Cadastro de Condição de Faturamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/CondicaoFaturamento/CondicaoFaturamentoCreate')
            },
            {
                path: 'condicaofaturamento/edit/:id',
                props: true,
                name: 'CondicaoFaturamentoEdit',
                meta: {
                    title: "Edição de Condição de Faturamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/CondicaoFaturamento/CondicaoFaturamentoEdit')
            },

            {
                path: 'processo',
                name: 'Processo',
                meta: {
                    title: "Processos",
                    permissions: ['processo']
                },
                component: () => import('@pages/Processo/Processo')
            },
            {
                path: 'processo/create',
                name: 'ProcessoCreate',
                meta: {
                    title: "Cadastro de Processo",
                    permissions: ['processo']
                },
                component: () => import('@pages/Processo/ProcessoCreate')
            },
            {
                path: 'processo/edit/:id',
                props: true,
                name: 'ProcessoEdit',
                meta: {
                    title: "Edição de Processo",
                    permissions: ['processo']
                },
                component: () => import('@pages/Processo/ProcessoEdit')
            },

            {
                path: 'servico',
                name: 'Servico',
                meta: {
                    title: "Serviços",
                    permissions: ['processo']
                },
                component: () => import('@pages/Servico/Servico')
            },
            {
                path: 'servico/create',
                name: 'ServicoCreate',
                meta: {
                    title: "Cadastro de Serviços"
                },
                component: () => import('@pages/Servico/ServicoCreate')
            },
            {
                path: 'servico/edit/:id',
                props: true,
                name: 'ServicoEdit',
                meta: {
                    title: "Edição de Serviços"
                },
                component: () => import('@pages/Servico/ServicoEdit')
            },

            {
                path: 'regiao',
                name: 'Regiao',
                meta: {
                    title: "Serviços"
                },
                component: () => import('@pages/Regiao/Regiao')
            },
            {
                path: 'regiao/create',
                name: 'RegiaoCreate',
                meta: {
                    title: "Cadastro de Regiões"
                },
                component: () => import('@pages/Regiao/RegiaoCreate')
            },
            {
                path: 'regiao/edit/:id',
                props: true,
                name: 'RegiaoEdit',
                meta: {
                    title: "Edição de Serviços"
                },
                component: () => import('@pages/Regiao/RegiaoEdit')
            },

            {
                path: 'observacoes',
                name: 'Observacoes',
                meta: {
                    title: "Observações de Orçamentos",
                    permissions: ['observacao_orcamento']
                },
                component: () => import('@pages/Observacoes/Observacoes')
            },
            {
                path: 'observacoes/create',
                name: 'ObservacoesCreate',
                meta: {
                    title: "Cadastro de Observações de Orçamento",
                    permissions: ['observacao_orcamento']
                },
                component: () => import('@pages/Observacoes/ObservacoesCreate')
            },
            {
                path: 'observacoes/edit/:id',
                props: true,
                name: 'ObservacoesEdit',
                meta: {
                    title: "Edição de Observações de Orçamento",
                    permissions: ['observacao_orcamento']
                },
                component: () => import('@pages/Observacoes/ObservacoesEdit')
            },
            {
                path: 'condicoesdesconto',
                name: 'CondicoesShow',
                meta: {
                    title: "Condições de Pagamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/Condicoes/CondicoesShow')
            },
            {
                path: 'condicoes',
                name: 'Condicoes',
                meta: {
                    title: "Condições de Pagamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/Condicoes/Condicoes')
            },
            {
                path: 'condicoes/create',
                name: 'CondicoesCreate',
                meta: {
                    title: "Cadastro de Condições de Pagamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/Condicoes/CondicoesCreate')
            },
            {
                path: 'condicoes/edit/:id',
                props: true,
                name: 'CondicoesEdit',
                meta: {
                    title: "Edição de Condições de Pagamento",
                    permissions: ['condicao_faturamento']
                },
                component: () => import('@pages/Condicoes/CondicoesEdit')
            },

            {
                path: 'descontovenda',
                name: 'DescontoVenda',
                meta: {
                    title: "Descontos de Vendedor",
                    permissions: ['desconto_vendedor']
                },
                component: () => import('@pages/DescontoVenda/DescontoVenda')
            },
            {
                path: 'descontovenda/create',
                name: 'DescontoVendaCreate',
                meta: {
                    title: "Cadastro de Descontos de Vendedor",
                    permissions: ['desconto_vendedor']
                },
                component: () => import('@pages/DescontoVenda/DescontoVendaCreate')
            },
            {
                path: 'descontovenda/edit/:id',
                props: true,
                name: 'DescontoVendaEdit',
                meta: {
                    title: "Edição de Descontos de Vendedor",
                    permissions: ['desconto_vendedor']
                },
                component: () => import('@pages/DescontoVenda/DescontoVendaEdit')
            },

            {
                path: 'descontofinanceiro',
                name: 'DescontoFinanceiro',
                meta: {
                    title: "Descontos Financeiros",
                    permissions: ['desconto_financeiro']
                },
                component: () => import('@pages/DescontoFinanceiro/DescontoFinanceiro')
            },
            {
                path: 'descontofinanceiro/create',
                name: 'DescontoFinanceiroCreate',
                meta: {
                    title: "Cadastro de Descontos Financeiros",
                    permissions: ['desconto_financeiro']
                },
                component: () => import('@pages/DescontoFinanceiro/DescontoFinanceiroCreate')
            },
            {
                path: 'descontofinanceiro/edit/:id',
                props: true,
                name: 'DescontoFinanceiroEdit',
                meta: {
                    title: "Edição de Descontos Financeiros",
                    permissions: ['desconto_financeiro']
                },
                component: () => import('@pages/DescontoFinanceiro/DescontoFinanceiroEdit')
            },

            {
                path: 'orcamento',
                name: 'Orcamento',
                meta: {
                    title: "Orçamentos",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/Orcamento')
            },
            {
                path: 'orcamento/create',
                name: 'OrcamentoCreate',
                meta: {
                    title: "Cadastro de Orçamento",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/OrcamentoCreate')
            },
            {
                path: 'orcamento/edit/:id',
                props: true,
                name: 'OrcamentoEdit',
                meta: {
                    title: "Editar Orçamento",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/OrcamentoEdit')
            },
            {
                path: 'orcamento/show/:id',
                props: true,
                name: 'OrcamentoShow',
                meta: {
                    title: "Visualização de Orçamento",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/OrcamentoShow')
            },
            {
                path: 'orcamento/orcamentohistorico/:id',
                props: true,
                name: 'OrcamentoHistorico',
                meta: {
                    title: "Visualização de Orçamento",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/OrcamentoHistorico')
            },
            {
                path: 'orcamento/mensagem/:id',
                name: 'OrcamentoMensagem',
                props: true,
                meta: {
                    title: "Mensagens de Orçamento",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Orcamento/OrcamentoMensagem')
            },
            {
                path: 'administrativo/orcamento',
                name: 'AdministrativoOrcamento',
                meta: {
                    title: "Mensagens de Orçamentos",
                    permissions: ['orcamento']
                },
                component: () => import('@pages/Administrativo/Orcamento')
            },
            {
                path: 'administrativo/fornecedores',
                name: 'AtualizacaoFornecedores',
                meta: {
                    title: "Atualização de Fornecedores",
                    permissions: ['fornecedor']
                },
                component: () => import('@pages/HistoricoPreco/AtualizacaoFornecedores')
            },
            {
                path: 'produtos/atualizar/precos',
                props: true,
                name: 'AtualizarPrecos',
                meta: {
                    title: "Atualização de Fornecedores",
                    permissions: ['atualizacao_fornecedor']
                },
                component: () => import('@pages/HistoricoPreco/AtualizarPrecos')
            },
            {
                path: 'produtos/historico/:id',
                props: true,
                name: 'HistoricoPrecoShow',
                meta: {
                    title: "Histórico de Atualizações",
                    permissions: ['atualizacao_fornecedor']
                },
                component: () => import('@pages/HistoricoPreco/HistoricoPrecoShow')
            },

            {
                path: 'ambiente/:id',
                name: 'AmbienteList',
                props: true,
                meta: {
                    title: "Gerenciamento de Ambiente"
                },
                component: () => import('@pages/Ambiente/AmbienteList')
            },

            {
                path: 'implantacao',
                name: 'Implantacao',
                meta: {
                    title: "Implantação",
                    permissions: ['implantacao']
                },
                component: () => import('@pages/Implantacao/Implantacao')
            },
            {
                path: 'implantacao/show/:id',
                props: true,
                name: 'ImplantacaoShow',
                meta: {
                    title: "Visualizar Implantação",
                    permissions: ['implantacao']
                },
                component: () => import('@pages/Implantacao/ImplantacaoShow')
            },
            {
                path: 'gestao',
                name: 'Gestao',
                meta: {
                    title: "Gestão de Implantação",
                    permissions: ['gestao_implantacao']
                },
                component: () => import('@pages/GestaoImplantacao/Gestao')
            },
            {
                path: 'agenda-entrega',
                name: 'AgendaEntrega',
                meta: {
                    title: "Agenda de Entregas",
                    permissions: ['agenda_entrega']
                },
                component: () => import('@pages/GestaoImplantacao/AgendaEntrega')
            },
            {
                path: 'agenda-montagem',
                name: 'AgendaMontagem',
                meta: {
                    title: "Agenda de Montagem",
                    permissions: ['agenda_montagem']
                },
                component: () => import('@pages/GestaoImplantacao/AgendaMontagem')
            },
            {
                path: 'agenda-entrega/visualizar/:id',
                props: true,
                name: 'AgendaEntregaShow',
                meta: {
                    title: "Visualizar Agenda de Entregas",
                    permissions: ['agenda_entrega']
                },
                component: () => import('@pages/GestaoImplantacao/AgendaEntregaShow')
            },
            {
                path: 'agenda-montagem/visualizar/:id',
                props: true,
                name: 'AgendaMontagemShow',
                meta: {
                    title: "Visualizar Agenda de Montagens",
                    permissions: ['agenda_montagem']
                },
                component: () => import('@pages/GestaoImplantacao/AgendaMontagemShow')
            },
            {
                path: 'agendar/:id',
                props: true,
                name: 'Agendar',
                meta: {
                    title: "Agendar Entrega",
                },
                component: () => import('@pages/GestaoImplantacao/Agendar')
            },
            {
                path: 'assistencia',
                name: 'Assistencia',
                meta: {
                    title: "Assistência Técnica",
                    permissions: ['assistencia_tecnica']
                },
                component: () => import('@pages/GestaoImplantacao/Assistencia')
            },
            {
                path: 'assistencia/create',
                name: 'AssistenciaCreate',
                meta: {
                    title: "Nova Assistência Técnica",
                    permissions: ['assistencia_tecnica']
                },
                component: () => import('@pages/GestaoImplantacao/AssistenciaCreate')
            },
            {
                path: 'assistencia/show/:id',
                props: true,
                name: 'AssistenciaShow',
                meta: {
                    title: "Visualizar Assistência Técnica",
                    permissions: ['assistencia_tecnica']
                },
                component: () => import('@pages/GestaoImplantacao/AssistenciaShow')
            },
            {
                path: 'relatorio/produto',
                name: 'RelatorioProduto',
                meta: {
                    title: "Relatório de Produto",
                    permissions: ['relatorio_produto']
                },
                component: () => import('@pages/Relatorio/Produto')
            },
            {
                path: 'relatorio/orcamento',
                name: 'RelatorioOrcamento',
                meta: {
                    title: "Relatório de Orçamento",
                    permissions: ['relatorio_orcamento']
                },
                component: () => import('@pages/Relatorio/Orcamento')
            },
            {
                path: 'relatorio/orcamentogn',
                name: 'RelatorioOrcamentoGn',
                meta: {
                    title: "Relatório Mensal GN",
                    permissions: ['relatorio_orcamento_gn']
                },
                component: () => import('@pages/Relatorio/OrcamentoGn')
            },
            {
                path: 'relatorio/gestaocusto',
                name: 'RelatorioGestaoCusto',
                meta: {
                    title: "Relatório de gestão de custo",
                    permissions: ['relatorio_gestao_custo']
                },
                component: () => import('@pages/Relatorio/RelatorioGestaoCusto')
            },
            {
                path: 'relatorio/implantacao',
                name: 'RelatorioImplantacao',
                meta: {
                    title: "Relatório de Implantação",
                    permissions: ['relatorio_implantacao']
                },
                component: () => import('@pages/Relatorio/Implantacao')
            },
            {
                path: 'relatorio/comissao',
                name: 'RelatorioComissao',
                meta: {
                    title: "Relatório de Comissão",
                    permissions: ['relatorio_comissao']
                },
                component: () => import('@pages/Relatorio/Comissao')
            },
            {
                path: 'relatorio/autorizacao',
                name: 'RelatorioAutorizacao',
                meta: {
                    title: "Relatório de Autorizações",
                    permissions: ['relatorio_autorizacao']
                },
                component: () => import('@pages/Relatorio/Autorizacao')
            },
            {
                path: 'relatorio/entrega',
                name: 'RelatorioEntrega',
                meta: {
                    title: "Relatório de Entrega",
                    permissions: ['relatorio_entrega']
                },
                component: () => import('@pages/Relatorio/Entrega')
            },
            {
                path: 'relatorio/assistencia',
                name: 'RelatorioAssistencia',
                meta: {
                    title: "Relatório de Assistência Técnica",
                    permissions: ['relatorio_assistencia']
                },
                component: () => import('@pages/Relatorio/Assistencia')
            },
            {
                path: 'perfil',
                name: 'Perfil',
                meta: {
                    title: "Perfil de Acesso",
                    permissions: ['perfil_acesso']
                },
                component: () => import('@pages/Perfil/Perfil')
            },
            {
                path: 'perfil/create',
                name: 'PerfilCreate',
                meta: {
                    title: "Cadastro de Perfil",
                    permissions: ['perfil_acesso']
                },
                component: () => import('@pages/Perfil/PerfilCreate')
            },
            {
                path: 'perfil/edit/:id',
                props: true,
                name: 'PerfilEdit',
                meta: {
                    title: "Edição de Perfil",
                    permissions: ['perfil_acesso']
                },
                component: () => import('@pages/Perfil/PerfilEdit')
            },
            {
                path: 'permissao/:id',
                props: true,
                name: 'Permissao',
                meta: {
                    title: "Permissões de Acesso",
                    permissions: ['perfil_acesso']
                },
                component: () => import('@pages/Perfil/Permissao')
            },
            {
                path: 'administrativo/logs',
                name: 'AdministrativoLogs',
                meta: {
                    title: "Logs",
                    permissions: ['log']
                },
                component: () => import('@pages/Administrativo/Logs')
            },


        ]
    },

    {
        path: "/print",
        meta: {
            requiresAuth: true
        },
        component: () => import('@templates/print/Template'),
        children: [{
                path: 'orcamento/print/:id/:tipoImpressao',
                props: true,
                name: 'OrcamentoPrint',
                meta: {
                    title: "Imprimir Orçamento"
                },
                component: () => import('@pages/Orcamento/OrcamentoPrint'),

            },
            {
                path: 'implantacao/print/:id',
                props: true,
                name: 'ImplantacaoPrint',
                meta: {
                    title: "Imprimir Implantacao"
                },
                component: () => import('@pages/Implantacao/ImplantacaoPrint')
            },
            {
                path: 'implantacaofornecedor/print/:fornecedor_id/:orcamento_id',
                props: true,
                name: 'ProdutoFornecedorPrint',
                meta: {
                    title: "Fornecedor - Lista de Produtos"
                },
                component: () => import('@pages/Implantacao/ProdutoFornecedorPrint')
            },
        ],
    },
    
    {
        path: '/autorizacaoterceiro/print/:terceirizado_id/:implantacao_id/:tipo',
        props: true,
        name: 'AutorizacaoPagamentoTerceiroPrint',
        meta: {
            title: "Terceirizado - Autorização de Pagamento"
        },
        component: () => import('@pages/Implantacao/AutorizacaoPagamentoTerceiroPrint')
    },
    {
        path: '/autorizacaofornecedor/print/:fornecedor_id/:implantacao_id/:valor',
        props: true,
        name: 'AutorizacaoPagamentoFornecedorPrint',
        meta: {
            title: "Fornecedor - Autorização de Pagamento"
        },
        component: () => import('@pages/Implantacao/AutorizacaoPagamentoFornecedorPrint')
    },
    {
        path: '/autorizacaoarquiteto/print/:implantacao_id',
        props: true,
        name: 'AutorizacaoPagamentoArquitetoPrint',
        meta: {
            title: "Arquiteto - Autorização de Pagamento"
        },
        component: () => import('@pages/Implantacao/AutorizacaoPagamentoArquitetoPrint')
    },
    {
        path: "/relatorio/entrega/print",
        name: "EntregaPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Entrega'),
    },
    {
        path: '/relatorio/produto/maisvendidos/print',
        name: 'MaisVendidosPrint',
        component: () => import('@pages/Relatorio/relatorioPrint/MaisVendidos')
    },
    {
        path: "/relatorio/implantacao/print",
        name: "ImplantacaoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Implantacao'),
    },
    {
        path: "/relatorio/gestaocusto/print",
        name: "GestaoCustoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/GestaoCusto'),
    },
    {
        path: "/relatorio/comissao/print",
        name: "ComissaoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Comissao'),
    },
    {
        path: "/relatorio/orcamento/print",
        name: "OrcamentoRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Orcamento'),
    },
    {
        path: "/relatorio/orcamentogn/print",
        name: "OrcamentoGnRelatorioPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/OrcamentoGn'),
    },
    {
        path: "/relatorio/produto/print",
        name: "ProdutoPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Produto'),
    },
    {
        path: "/relatorio/assistencia/print",
        name: "AssistenciaPrint",
        component: () => import('@pages/Relatorio/RelatorioPrint/Assistencia'),
    },
    {
        path: "/agenda-entrega/relatorio/:id",
        name: "AgendaEntregaPrint",
        props: true,
        meta: {
            title: "Imprimir Relatório de Entrega"
        },
        component: () => import('@pages/GestaoImplantacao/Print/AgendaEntrega'),
    },
    {
        path: "/agenda-montagem/relatorio/:id",
        name: "AgendaMontagemPrint",
        props: true,
        meta: {
            title: "Imprimir Relatório de Montagem"
        },
        component: () => import('@pages/GestaoImplantacao/Print/AgendaMontagem'),
    },
    {
        path: "/agenda/implantacao/print/:id",
        name: "AgendaImplantacaoPrint",
        props: true,
        meta: {
            title: "Imprimir Implantacao"
        },
        component: () => import('@pages/GestaoImplantacao/AgendaImplantacaoPrint'),
    },
    {
        path: "/relatorio/autorizacao/print",
        name: "AutorizacaoRelatorioPrint",
        meta: {
            title: "Imprimir Relatório de Autorização"
        },
        component: () => import('@pages/Relatorio/RelatorioPrint/Autorizacao'),
    },


    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login'),
        //meta: {guest: true},
    },

]


const router = new VueRouter({
    mode: "history",
    routes
})

function loggedIn() {
    return store.state.isLogged;
}


const DEFAULT_TITLE = 'Ampia Design';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = 'Ampia Design - ' + to.meta.title || DEFAULT_TITLE;
    });
})

router.beforeEach((to, from, next) => {
    const userPermissions = store.state.user && store.state.user.funcionario && store.state.user.funcionario.perfil ? store.state.user.funcionario.perfil.permissoesList : [];

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn()) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            if (to.meta.permissions && to.meta.permissions.length > 0) {
                if (!store.state.user.admin) {
                    let isAllowed = userPermissions.some(p => to.meta.permissions.includes(p))
                    if (!isAllowed) {
                        return
                    }
                }
            }
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
